import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CustomNavbar = ({ data }) => {
  return (
    <>
      {/* <Link to="/"><img alt="Logo" src={data.logo_png.publicURL} height="26" width="26" className="centered1" /></Link> */}
      <Link to="/"><div className="centered">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"
            width="7mm" height="7mm"
            viewBox="0 0 491 492" id="logo_roel">
            <path id="left"
              fill="#0070c0" stroke="#0070c0" strokeWidth="1"
              d="M 0.00,171.00
                                C 0.00,171.00 0.00,321.00 0.00,321.00
                                0.00,321.00 42.00,321.00 42.00,321.00
                                42.00,321.00 42.00,171.00 42.00,171.00
                                42.00,171.00 0.00,171.00 0.00,171.00 Z" />
            <path id="up"
              fill="red" stroke="red" strokeWidth="1"
              d="M 170.00,0.00
           C 170.00,0.00 170.00,42.00 170.00,42.00
             170.00,42.00 320.00,42.00 320.00,42.00
             320.00,42.00 320.00,0.00 320.00,0.00
             320.00,0.00 170.00,0.00 170.00,0.00 Z" />
            <path id="right"
              fill="#548235" stroke="#548235" strokeWidth="1"
              d="M 449.00,171.00
           C 449.00,171.00 449.00,321.00 449.00,321.00
             449.00,321.00 491.00,321.00 491.00,321.00
             491.00,321.00 491.00,171.00 491.00,171.00
             491.00,171.00 449.00,171.00 449.00,171.00 Z" />
            <path id="right"
              fill="#dfda00" stroke="#dfda00" strokeWidth="1"
              d="M 170.00,450.00
           C 170.00,450.00 170.00,492.00 170.00,492.00
             170.00,492.00 320.00,492.00 320.00,492.00
             320.00,492.00 320.00,450.00 320.00,450.00
             320.00,450.00 170.00,450.00 170.00,450.00 Z
           M -98.00,472.00" />
          </svg>
        </div>
      </div></Link>
      <div className="container">
        <div className="d-flex justify-content-between navigation">
          <div style={{ marginLeft: '20px' }}>
            <span className="pr-3 pr-md-4 pr-lg-5" id="prospectNav"><AnchorLink to="/#prospect">prospect</AnchorLink></span>
            <span id="aboutNav"><AnchorLink to="/#about">about</AnchorLink></span>
          </div>
          <div>
            <span className="pr-3 pr-md-4 pr-lg-5" id="retrospectNav"><AnchorLink to="/#retrospect">retrospect</AnchorLink></span>
            <span id="contactNav"><AnchorLink to="/#contact">contact</AnchorLink></span>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomNavbar;
