import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import HomeNavbar from "./homeNav";
import Navbar from "./navBar";
import Footer from "./footer";
import ScrollUpComponent from "./scrollUp";

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query {
        strapiHomepage {
          logo_png {
            publicURL
          }
        }
      }
    `}
    render={(data) => (
      <>
        <ScrollUpComponent />
        {/* <Seo seo={seo} /> */}
        <header>
          {pageInfo.pageName === 'index' ?
            <HomeNavbar data={data.strapiHomepage} />
            :
            <Navbar data={data.strapiHomepage} />
          }
        </header>
        <main>{children}</main>
        <footer className="footer">
          <Footer />
        </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
