import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const HomeNavbar = ({ data }) => {
  const [index, setIndex] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);


  useEffect(() => {
    let prospect = document.getElementById("prospect").getBoundingClientRect().top + document.getElementById("prospect").ownerDocument.defaultView.pageYOffset - 70;
    let about = document.getElementById("about").getBoundingClientRect().top + document.getElementById("about").ownerDocument.defaultView.pageYOffset - 70;
    let retrospect = document.getElementById("retrospect").getBoundingClientRect().top + document.getElementById("retrospect").ownerDocument.defaultView.pageYOffset - 70;
    let contact = document.getElementById("contact").getBoundingClientRect().top + document.getElementById("contact").ownerDocument.defaultView.pageYOffset - 70;
    function onScroll() {
      let currentPosition = window.pageYOffset;
      if (currentPosition < document.getElementById("prospect").getBoundingClientRect().top) { setIndex(0) }
      if (currentPosition >= prospect) { setIndex(1) }
      if (currentPosition >= about) { setIndex(2) }
      if (currentPosition >= retrospect) { setIndex(3) }
      if (currentPosition >= contact) { setIndex(4) }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <>
      {/* <Link to="/"><img alt="Logo" src={data.logo_png.publicURL} height="26" width="26" className="centered1" /></Link> */}
      <Link to="/"><div className="centered">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg"
            width="7mm" height="7mm"
            viewBox="0 0 491 492" id="logo_roel">
            <path id="left"
              fill="#0070c0" stroke="#0070c0" strokeWidth="1"
              d="M 0.00,171.00
                                C 0.00,171.00 0.00,321.00 0.00,321.00
                                0.00,321.00 42.00,321.00 42.00,321.00
                                42.00,321.00 42.00,171.00 42.00,171.00
                                42.00,171.00 0.00,171.00 0.00,171.00 Z" />
            <path id="up"
              fill="red" stroke="red" strokeWidth="1"
              d="M 170.00,0.00
           C 170.00,0.00 170.00,42.00 170.00,42.00
             170.00,42.00 320.00,42.00 320.00,42.00
             320.00,42.00 320.00,0.00 320.00,0.00
             320.00,0.00 170.00,0.00 170.00,0.00 Z" />
            <path id="right"
              fill="#548235" stroke="#548235" strokeWidth="1"
              d="M 449.00,171.00
           C 449.00,171.00 449.00,321.00 449.00,321.00
             449.00,321.00 491.00,321.00 491.00,321.00
             491.00,321.00 491.00,171.00 491.00,171.00
             491.00,171.00 449.00,171.00 449.00,171.00 Z" />
            <path id="right"
              fill="#dfda00" stroke="#dfda00" strokeWidth="1"
              d="M 170.00,450.00
           C 170.00,450.00 170.00,492.00 170.00,492.00
             170.00,492.00 320.00,492.00 320.00,492.00
             320.00,492.00 320.00,450.00 320.00,450.00
             320.00,450.00 170.00,450.00 170.00,450.00 Z
           M -98.00,472.00" />
          </svg>
        </div>
      </div></Link>
      <div className="container">
        <div className="d-flex justify-content-between navigation">
          <div style={{ marginLeft: '20px' }}>
            <span className="pr-3 pr-md-4 pr-lg-5" id="prospectNav"><AnchorLink to="/#prospect" className={index === 1 ? 'active' : ''}>prospect</AnchorLink></span>
            <span id="aboutNav"><AnchorLink to="/#about" className={index === 2 ? 'active' : ''}>about</AnchorLink></span>
          </div>
          <div>
            <span className="pr-3 pr-md-4 pr-lg-5" id="retrospectNav"><AnchorLink to="/#retrospect" className={index === 3 ? 'active' : ''}>retrospect</AnchorLink></span>
            <span id="contactNav"><AnchorLink to="/#contact" className={index === 4 ? 'active' : ''}>contact</AnchorLink></span>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeNavbar;
