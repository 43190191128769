import React from "react";

const CustomFooter = () => {
    return (
        <>
            <div className="container">
                <p>&copy;
                {(new Date().getFullYear())} Roel Heremans
            </p>
            </div>
        </>
    )
}

export default CustomFooter
